<template>
  <b-modal
    id="onlending-details-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @hidden="closeModal"
    @show="showModal"
  >
    <div class="modal-header">
      <div class="modal-title">Editar e ver detalhes do repasse</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <b-container class="py-3 px-4">
      <b-row>
        <b-col cols="2">
          <p class="label">Favorecido</p>
          <p class="info">{{onlending?.beneficiary?.name ?? ''}}</p>
        </b-col>

        <b-col cols="2">
          <p class="label">Data atend.</p>
          <p class="info">
            {{
              onlending?.item_type === 'App\\Models\\TissGuide\\Item\\TissGuideItem' ?
              (onlending.item?.execution_date ? moment(onlending.item?.execution_date).format('DD/MM/YYYY') : '') :
              (onlending.item?.appointment?.start_datetime ? moment(onlending.item?.appointment?.start_datetime).format('DD/MM/YYYY') : ''),            
            }} 
          </p>
        </b-col>

        <b-col cols="2">          
          <span
            class="status-tag py-2 px-3"
            :style="`background: ${statusColor}`"
          >
            Repasse: {{onlending ? onlending.status : ''}}
          </span>
        </b-col>
      </b-row>
      
      <hr class="my-4"/>

      <b-table-simple striped>
        <tbody>
          <tr class="tr-header">
            <th>Paciente</th>
            <th>Convênio</th>
            <th>Descrição</th>
            <th>Situação</th>
            <th>Forma pag.</th>
          </tr>

          <tr class="tr-header">
            <td>
              {{
                onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                (onlending.origin?.appointment?.patient?.name ?? (onlending.origin?.patient?.name ?? '-')):
                onlending.item?.appointment?.patient?.name
              }}
            </td>
            <td>{{onlending?.health_plan?.fantasy_name ?? ''}}</td>
            <td>{{ onlending.item?.item?.name ?? ''}}</td>
            <td>
              <span 
                v-if="onlending?.onlendingSituation" 
                class="situation-tag" 
                :style="`background: ${ colors[onlending.onlendingSituation] }`">
                {{onlending.onlendingSituation}} 
              </span> 
            </td>
            <td>
              {{
                onlending.origin_type === 'App\\Models\\TissGuide\\TissGuide' ?
                  onlending.origin?.invoicing_tiss_setting?.payment_method?.name :
                  onlending.payment?.payment_method?.name
              }}
            </td>             
          </tr>
        </tbody>
      </b-table-simple>

      <hr />
      
      <b-table-simple>
        <thead>
          <tr class="edit-table-head">
            <th width="35%">Regra aplicada</th>
            <th width="25%">Perfil/Grau part.</th>
            <th width="15%">Valor proced.</th>
            <th width="15%">Valor repasse</th>
            <th width="10%">Valor %</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-header">            
            <td width="35">
              <div v-if="onlending?.status  !== 'Finalizado'">
                <multiselect
                  class="with-border"
                  v-model="form.rule_id"
                  placeholder="Selecionar"
                  :options="ruleOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  label="label"
                  value="id"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChervonDown />
                    </div>
                  </template>
                  <template slot="noResult">Nenhum resultado encontrado</template>
                </multiselect>
                <div v-if="validated && !form.rule_id" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </div>
              
              <span v-else>
                {{'Regra #'+ onlending.rule_definition.rule.number + ' - ' +  onlending.rule_definition?.rule?.name}} 
              </span>
            </td>
            <td width="25">
              <div v-if="onlending?.status && !['A pagar', 'Finalizado'].includes(onlending.status)">
                <multiselect
                  class="with-border"
                  v-model="form.definition_id"
                  placeholder="Selecionar"
                  :options="definitionOptions"
                  :allow-empty="true"
                  :option-height="40"
                  :showLabels="false"
                  label="label"
                  value="id"
                  @select="(val) => changeValues(val)"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChervonDown />
                    </div>
                  </template>
                  <template slot="noResult">Nenhum resultado encontrado</template>
                </multiselect>
                <div v-if="validated && !form.definition_id" class="custom-invalid-feedback">
                  Campo obrigatório
                </div>
              </div>
            
              <span v-else>
                {{ definitionOptions ? definitionOptions.find(definition => definition.id === form?.definition_id?.id)?.label : ''}}
              </span>
            </td>
            <td width="10">{{ parseNumberToMoney(parseFloat(onlending?.total_value ?? 0))}}</td>
            <td width="10"> 
              <money
                :readonly="onlending?.status && ['A pagar', 'Finalizado'].includes(onlending.status)"
                class="form-control"  
                v-model="form.onlending_value"    
              />
            </td>
            <td width="10">{{((form.onlending_value * 100) / form.total_value).toFixed(2) + '%'}}</td>
          </tr>
        </tbody>
      </b-table-simple>

      <hr />

      <b-row class="d-flex justify-content-end mb-2">
        <b-button
          class="mr-2"
          variant="outline-primary"
          @click="closeModal"
        >
          Voltar
        </b-button>
        <b-button
          v-if="onlending?.status !== 'Finalizado'"
          v-can="'RepMedRel2'"
          variant="primary"
          @click="updateOnlending"
        >
          Salvar alterações
        </b-button>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import api from '@/modules/onlending/api.js'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'OnlendingDetailsModal',
  components: {
    ChervonDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
  },
  props: {
    onlending: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    statusColor() {       
      switch(this.onlending.status) {
        case 'Em aberto':
          return '#FDFECE';
        case 'A revisar':
          return '#E2CCFF';
        case 'Extrato':
          return '#E7FFDB';
        case 'Finalizado':
          return '#E7ECFE';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      definitionOptions: [],
      ruleOptions: [],

      colors: {
        Aberta: '#FCDEC2',
        Enviado: 'var(--light-orange-200)',
        'Em elaboração': '#FCDEC2',
        'Paga': 'var(--states-success-soft-green)',
        Pago: 'var(--states-success-soft-green)',
        'Pag. Parcial': '#E7FFDB',
        'Pago parcialmente': '#E7FFDB',
        'Em recurso': '#E2CCFF',
        Recuperado: 'var(--blue-100)',
        Glosada: '#FED2CE',
        Glosado: '#FED2CE',
        Devolvido: '#FFF5BF',
        Autorizada: '#E7ECFE;',
        Finalizada: 'var(--neutral-500)'
      },

      form: {},
      firstLoad: true,

      validated: false
    }
  },
  methods: {
    parseNumberToMoney,
    showModal(){
      this.form = this.getDefaultForm()
      this.getRules()
    },
    getDefinitions() {
      const isLoading = this.$loading.show()
      api.getOnlendingRuleDefinitionsByRule( this.form.rule_id.id)
      .then((res) => {
        this.definitionOptions = res.data
        this.definitionOptions.map(definition => {
          definition.label = definition.profile.name;
        });
        this.form.definition_id = this.definitionOptions && this.onlending?.definition_id && this.onlending.definition_id ? this.definitionOptions.find(option => option.id === this.onlending.definition_id) : null

      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    getRules() {
      const isLoading = this.$loading.show()
      api.getOnlendingRulesByItemAndBeneficiary({
        clinic_id: this.clinic.id,
        beneficiary_id: this.onlending?.beneficiary_id ?? null,
        item_id: this.onlending.item.item.id,
        item_type: 'Procedimento'
      })
      .then((res) => {
        this.ruleOptions = res.data
        this.ruleOptions.map(rule => {
          rule.label = `Regra #${rule.number} - ${rule.name}`;
        });
        this.form.rule_id = this.ruleOptions.find(option => option.label === ('Regra #'+ this.onlending.rule_definition.rule.number + ' - ' +  this.onlending.rule_definition?.rule?.name)) ?? null
      })
      .catch ((error) => {
        this.$toast.error(error.message)
      })
      .finally (() => {
        isLoading.hide()
      })
    },

    getDefaultForm() {
      return {
        definition_id: null,
        rule_id: null,
        total_value: parseFloat(this.onlending.total_value ?? 0),
        onlending_value: parseFloat(this.onlending.onlending_value ?? 0)
      }
    },

    async updateOnlending(){
      this.validated = true
      if(!this.form.definition_id || !this.form.rule_id) return
      if (((this.form.onlending_value * 100) / this.form.total_value).toFixed(0) > 100){
        this.$toast.warning('O valor do repasse não deve ser maior que o valor do procedimento')
        return
      }
      const isLoading = this.$loading.show()
      try {
        if (typeof this.form.definition_id === 'object') this.form.definition_id = this.form.definition_id ? this.form.definition_id.id : null
        await api.updateOnlending(this.onlending.id, {
          ...this.form,
          clinic_id: this.clinic.id        
        })
        this.$toast.success('Repasse editado com sucesso')
        this.$emit('updateOnlending')
        isLoading.hide()
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    changeValues(val){
      if(val.format === 'R$ reais') this.form.onlending_value = val?.value > this.form.total_value ? this.form.total_value : val?.value
      else{
        let calculedVal = this.form.total_value * (val?.value/100)
        if(val?.rule?.specification?.guaranteed_minimum_rule && calculedVal < val?.rule?.specification?.guaranteed_minimum) calculedVal = val?.rule?.specification?.guaranteed_minimum
        if(calculedVal > this.form.total_value) calculedVal = this.form.total_value
        this.form.onlending_value = calculedVal 
      }   
    },
    closeModal() {
      this.$emit('closeModal')
      this.$bvModal.hide('onlending-details-modal')
    },
  },
  watch: {
    'form.rule_id': {
      handler(val) {
        if(val){
          this.form.definition_id = null
          this.getDefinitions()
        } 
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#onlending-details-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;

    .main-content {
      display: flex;
      flex-direction: row;
      flex-direction: flex-start;
      justify-content: left;

      div:nth-child(n + 2) {
        margin-left: 5%;
      }
    }

    .filters {
      margin-top: 4vh !important;
    }

    .section-table {
      padding: 24px;
      border: 1px solid var(--neutral-300);
      border-radius: 8px;

      h3 {
        width: 700;
        size: 18px;
        color: var(--dark-blue);
      }
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .status-tag {
    width: 100%;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    color: var(--type-active);
    font-size: 14px;
  }

  .situation-tag {
    width: 100%;
    border-radius: 16px;
    padding: 5px 10px;
    background-color: var(--neutral-300);
    display: inline-block;
    text-align: center;
  }

  .label{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 130%;
    color: var(--dark-blue);
    margin-bottom: 6px;
  }

  .info{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 130%;
    color: var(--type-active);
  }

  table th, .table td {
    padding: 0.75rem 0;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    /* border: none; */
  }

  .tr-header {
    th {
      padding: 16px 8px;
    }

    .table th, .table td {
      padding: 0;
      vertical-align: middle;
      border-top: none;
    }

    td {
      padding: 16px 8px !important;
    }
  }

  th, td {
    text-align: left;
  }

  th {
    font-size: 14px;
    color: var(--dark-blue);
  }

  .td{
    font-size: 15px;
    color: var(--type-active);
    padding: 0.75rem !important;
  }

  .edit-table-head{
    background: var(--blue-100);
    th{
      padding: 16px 8px;
    }
  }
}
</style>